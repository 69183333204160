import {
  isMobile,
  scrollTo
} from "./helper";
import {
  toggleMenu,
  addSticky,
  closeMenu
} from "./navigation";
import "babel-polyfill";
import SiteHeader from "./siteHeader";
import getAjaxMemberPage from "./ajaxes";
import $ from "jquery";

window.addEventListener("DOMContentLoaded", () => {
  toggleMenu();

  getAjaxMemberPage($(".till__member .post__permalink"));

  const Header = new SiteHeader();
  if (
    !document.querySelector("body").classList.contains("page-template-homepage")
  )
    Header.setBodyPaddingTop();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

  Header.stickyHeader();

});

if(!isMobile()) {
  // window.location.hash = '';
  history.pushState('', document.title, window.location.pathname);
} 

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

if(isMobile()) {
  scrollTo();
}
