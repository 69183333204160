// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick'
import 'slick-carousel/slick/slick.css'

/****** SLIDERS *******/
const sliders = {
    home: $('#home-slider')
}

const activePage = (index, active) => {
    let linksToPage =  document.querySelectorAll('.home-slider-container .offer-children-container .hentry');

    linksToPage.forEach(el => {
        el.classList.remove('active')

        if (index == el.getAttribute('data-index')) 
            el.classList.add('active');
    })

}

const activePagg = (index) => {
    let pagination = document.querySelectorAll('.home-slider-pagination .slick-dots li');

    pagination.forEach(el => {
        el.classList.remove('active')
        if (index == $(el).index())  {
            el.classList.add('active');
        }
    })
}

sliders.home.on('init', function() {
    activePage(0);
    activePagg(0);
})


sliders.home.slick({
    dots: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: jsData.sliderAutoplaySpeed ? jsData.sliderAutoplaySpeed : 3000,
    speed: 1500,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    appendDots: $('.home-slider-pagination')
});

//pagination fix(pagination in slide)
sliders.home.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    activePage(nextSlide);
    activePagg(nextSlide);
});

