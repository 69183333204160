import $ from "jquery";

const getAjaxMemberPage = button => {
  button.click(function(e) {
    
    e.preventDefault();
    
    let postID = $(this).attr("data-post-id");
    let $container = $(".ajax-page-container");
    let $loader = $(".loader");
    let html = $('html');

    $.ajax({
      type: "POST",
      url: jsData.ajaxUrl,
      data: {
        action: "ajaxMemberPage",
        postID: postID
      },
      beforeSend: function() {
        $loader.show();
        $container.append('<div class="overlay active"></div>');
      },
      success: function(response) {
        if (response) {
          $container.append(response);
          $(".ajax-page-container").addClass("active");
          $loader.hide();
        }
      },
      complete: function() {
        html.css('overflow-Y', 'hidden');
        $(".overlay, .close").click(function(e) {

          e.preventDefault();
          html.css('overflow-Y', 'auto');

          $(".ajax-page-container").removeClass("active");
          $(this).removeClass("active");
          setTimeout(() => {
            $(".ajax-page-container .member").remove();
            $(".overlay").remove();
            $(this).remove();
          }, 350);
        });
      },
      error: function(error) {
        console.log(error);
      }
    });
  });
};

export default getAjaxMemberPage;
