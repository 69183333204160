//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from 'smooth-scroll';

window.addEventListener('DOMContentLoaded', () => {
    window.location.href.split('#')[0];

    new SmoothScroll('a[href*="#"]', {
        ignore: '[data-vc-container]',
        offset: '100'
    })
}) 